import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import Apis from '../../services/Apis';

const {getNavigationlist, getMasterDataService, getDocumentationlist, getHomeDatalist} = Apis();

export const fetchMasterData = createAsyncThunk("fetchMasterData", async () => {
    const response = await getMasterDataService();
    return response.data;
});

export const fetchNavigation = createAsyncThunk("fetchNavigation", async () => {
    const response = await getNavigationlist();
    return response.data;
});

export const fetchDocumentation = createAsyncThunk("fetchDocumentation", async () => {
    const response = await getDocumentationlist();
    return response.data;
});

export const fetchHomeData = createAsyncThunk("fetchHomeData", async () => {
    const response = await getHomeDatalist();
    return response.data;
});

const masterSlice = createSlice({
    name:"master",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        navigation: null,
        documentData: null,
        homeData: null,
    },
    extraReducers: (builder) => {
        //master data
        builder.addCase(fetchMasterData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMasterData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchMasterData.rejected, (state, action) => {
            state.isError= true;
        });

        //Navigation List
        builder.addCase(fetchNavigation.pending, (state, action) => {
            state.isLoading=true;
        });
        builder.addCase(fetchNavigation.fulfilled, (state,action) => {
            state.isLoading=false;
            state.navigation=action.payload;
        });
        builder.addCase(fetchNavigation.rejected, (state, action) => {
            state.isError=true;
        });

        //Documentation List
        builder.addCase(fetchDocumentation.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDocumentation.fulfilled, (state, action) => {
            state.isLoading = true;
            state.documentData = action.payload;
        });
        builder.addCase(fetchDocumentation.rejected, (state, action) => {
            state.isError=true;
        });

        //Documentation List
        builder.addCase(fetchHomeData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHomeData.fulfilled, (state, action) => {
            state.isLoading = true;
            state.homeData = action.payload;
        });
        builder.addCase(fetchHomeData.rejected, (state, action) => {
            state.isError=true;
        });
    }
});

export default masterSlice.reducer;