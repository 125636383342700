import React from 'react'
import { BsExclamationCircleFill } from 'react-icons/bs'

const InputBox = ({ label, id, handleChange, errors,...props}) => {
  return (
    <>
      <input
        {...props}
        type={id == 'password' ? 'password' : "text"} name={id} size="40" aria-required="true" placeholder={label} className={`w-100 ${errors.hasOwnProperty(id) ? 'is-error' : ''}`} required onChange={handleChange} /> 
        <span className='error'>{errors.hasOwnProperty(id) && 
            <><BsExclamationCircleFill className="error-icon" /> {errors[id]}</>
        } 
        
        </span> 
    </>
  )
}

export default InputBox
