import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import bg from '../../images/audits_header.jpg'
import { VscGraph } from "react-icons/vsc";
import { RiTeamFill } from "react-icons/ri";
import { MdInsights } from "react-icons/md";

const Slider = () => {
  return (
    <>
        <div className="finance-banner" style={{ backgroundImage: `url('${bg}')`}}>
            <div className="container">
                <div className="space-70"></div>
                <div className="row">
                    <div className="col-lg-7 pr-xl-0">
                        <h2>All-in-One<br/> Platform For<br/>
                            <span style={{color: "#008000"}}>
                                <Typewriter
                                    options={{
                                    strings: ['Audits', 'Asset Management'],
                                    autoStart: true,
                                    loop: true,
                                    }}
                                />
                            </span>
                        </h2>
                        <p className='pr-xl-5'>Minimize risk at every level with Auditsbay, the mobile audit management software that enhances quality and boosts safety instantly</p>
                        {/* <div className="ot-div">
                            <div class="ot-button">
                                <a href="#" class="octf-btn octf-btn-main" data-bs-target="#exampleModal" data-bs-toggle="modal">Get In Touch </a>
                            </div>
                            <div className="box-s1 icon-left bline-no">
                                <div className="icon-box icon-box-2">
                                    
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="space-120"></div>
            </div>
        </div>
        <section class="business-service-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="space-60 d-lg-none"></div>
                    <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
                        <div class="bline-yes business-icon-service">
                            <div class="icon-box icon-box-1 service-style text-center">
                                <div class="icon-main">
                                    <span class="circle-animate"></span>
                                    <VscGraph />
                                </div>
                                <div class="content-box">
                                    <h5 class="title-box">Collect Data</h5>
                                    <p>Create and assign powerful data collection workflows.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-5 mb-md-0">
                        <div class="bline-yes business-icon-service">
                            <div class="icon-box icon-box-1 service-style text-center">
                                <div class="icon-main">
                                    <span class="circle-animate"></span>
                                    <RiTeamFill />
                                </div>
                                <div class="content-box">
                                    <h5 class="title-box">Guide Teams</h5>
                                    <p>Guide task completion on mobile with context for teams.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="bline-yes business-icon-service">
                            <div class="icon-box icon-box-1 service-style text-center">
                                <div class="icon-main">
                                    <span class="circle-animate"></span>
                                    <MdInsights />
                                </div>
                                <div class="content-box">
                                    <h5 class="title-box">Gain Clarity</h5>
                                    <p>Quickly Derive Solutions and Automate Actions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-90"></div>
            </div>
        </section>
    </>
  )
}

export default Slider
