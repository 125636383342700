import React from 'react'
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { SiMinutemailer } from "react-icons/si";
import pic from '../../images/contactus_img.png'
import wallpaper from '../../images/contactus_bg1.png'

const ContectContent = () => {
  return (
    <div className="contact-page" style={{backgroundImage: `url(${wallpaper})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6 mb-5 mb-xl-0">
                        <div className="ot-heading">
                            <span className="">CONTACT DETAILS</span>
                            <h3 className="main-head">Our Contacts</h3>
                        </div>
                        <div className="space-20"></div>
                        <div className="space-2"></div>
                        <p>Give us a call or drop an email, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
                        <div className="space-10"></div>
                        <div class="box-s1 icon-left">
                            <div class="icon-box icon-box-2 contact-iconb">
                                <div class="icon-main">
                                    <FaMapMarkerAlt size={25} style={{ color: '#55bb53' }} className='icon'/>
                                </div>
                                <div class="content-box">
                                    <h6 class="title-box">Visit Us Daily</h6>
                                    {/* {
                                        Object.keys(maindata).length > 0 &&
                                        <p>{maindata.vendor.domain_address}</p>
                                    } */}
                                </div>
                            </div>
                            <div class="space-30"></div>
                            <div class="icon-box icon-box-2 contact-iconb">
                                <div class="icon-main contact-icon-phone">
                                    <FaPhoneAlt size={25} style={{ color: '#55bb53' }} className='icon'/>
                                </div>
                                <div class="content-box">
                                    {/* <h6 class="title-box"><a href={`tel:+91-${Object.keys(maindata).length > 0 && maindata.vendor.domain_phone}`}>Call Us 24/7</a></h6> */}
                                    {/* {
                                        Object.keys(maindata).length > 0 &&
                                        <p>+91-{maindata.vendor.domain_phone}</p>
                                    } */}
                                </div>
                            </div>
                            <div class="space-30"></div>
                            <div class="icon-box icon-box-2 contact-iconb">
                                <div class="icon-main contact-icon-mail">
                                    <SiMinutemailer size={25} style={{ color: '#55bb53' }} className='icon'/>
                                </div>
                                <div class="content-box">
                                    {/* <h6 class="title-box"><a href={`mailto: ${Object.keys(maindata).length > 0 && maindata.vendor.domain_email}`}>Mail Us</a></h6>
                                    {
                                        Object.keys(maindata).length > 0 &&
                                        <p>{maindata.vendor.domain_email}</p>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="">
                            <img src={pic} alt="" className='img-fluid'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ContectContent
