import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    hastoggle: false,
    showsubmenu: false,
    langdata: [],
    slectedkeys: '',
    dochomedata: true,
    showsearchdata: false,
    showSidebar: false,
}

export const toggleSlice = createSlice({
    name: 'localState',
    initialState,
    reducers: {
        setToggleTrue: (state) => {
            state.hastoggle = true; // Update hastoggle to true
        },
        setToggleFalse: (state) => {
            state.hastoggle = false; // Update hastoggle to false
        },
        toggleShowSubmenu: (state) => {
            state.showsubmenu = !state.showsubmenu; // Toggles the value of showsubmenu
        },
        setdochomedataTrue: (state) => {
            state.dochomedata = true; // Update dochomedata to true
        },
        setdochomedataFalse: (state) => {
            state.dochomedata = false; // Update dochomedata to false
        },
        setshowsearchdataTrue: (state) => {
            state.showsearchdata = true; // Update showsearchdata to true
        },
        setshowsearchdataFalse: (state) => {
            state.showsearchdata = false; // Update showsearchdata to false
        },
        setshowSidebarTrue: (state) => {
            // alert()
            state.showSidebar = true; // Update showSidebar to true
        },
        setshowSidebarFalse: (state) => {
            state.showSidebar = false; // Update showSidebar to false
        },
        toggleshowSidebar: (state) => {
            state.showSidebar = !state.showSidebar; // Toggles the value of showSidebar
        },
    },
})

// Action creators are generated for each case reducer function
export const { setToggleTrue, setToggleFalse, toggleShowSubmenu, setdochomedataTrue, setdochomedataFalse, setshowsearchdataTrue, setshowsearchdataFalse, toggleshowSidebar, setshowSidebarTrue, setshowSidebarFalse} = toggleSlice.actions

export default toggleSlice.reducer