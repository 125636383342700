import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import Apis from '../../services/Apis';

const {SearchDocument} = Apis();

export const fetchSearchDocument = createAsyncThunk("fetchSearchDocument", async (input_data) => {
    const response = await SearchDocument(input_data);
    return response.data;
});

const searchSlice = createSlice({
    name:"search",
    initialState: {
        isLoading: false,
        searchPost: null,
        isError: false,
    },
    extraReducers: (builder) => {
        //master data
        builder.addCase(fetchSearchDocument.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchSearchDocument.fulfilled, (state, action) => {
            state.isLoading = false;
            state.searchPost = action.payload;
        });
        builder.addCase(fetchSearchDocument.rejected, (state, action) => {
            state.isError= true;
        });

        
    }
});

export default searchSlice.reducer;