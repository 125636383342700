import React from 'react'

const Features = () => {
  return (
    <>
        <div className="consulting-priorities">
            <div className="container">
                <div className="space-30"></div>
                <div className="row">
                    <div className="col-lg-7 offset-lg-5">
                        <div className="consulting-priorities-desc">
                            <div className="ot-heading">
                                <span className="">FEATURES</span>
                                <h3 className="main-head">Guiding Principles for Exceptional Solutions</h3>
                            </div>
                            <div className="space-20"></div>
                            <div className="space-2"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Low-Code Workflow Builder</h6>
                                    <p>A low-code workflow builder enables quick creation of automated workflows with minimal coding, enhancing efficiency.</p>
                                </div>
                            </div>
                            <div className="space-10"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Real-Time Tracking & Reporting</h6>
                                    <p>Real-Time Tracking & Reporting enables instant monitoring and analysis, enhancing decision-making and operational efficiency.</p>
                                </div>
                            </div>
                            <div className="space-10"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Automated Escalation & Alerts</h6>
                                    <p>Automated escalation and alerts streamline incident management by quickly notifying the right personnel for resolution.</p>
                                </div>
                            </div>
                            <div className="space-10"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Photo Capture & Markup</h6>
                                    <p className="mb-0">Photo Capture & Markup allows users to take pictures and annotate them with text, shapes, and highlights.</p>
                                </div>
                            </div>
                            <div className="space-10"></div>
                            <div className="icon-list d-flex">
                                <div className="icon-main">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.556 515.556"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"></path></svg>
                                </div>
                                <div className="content-box">
                                    <h6 className="title-box">Automated Notifications & Alerts</h6>
                                    <p className="mb-0">Automated notifications and alerts streamline communication, ensuring timely updates and responses for efficient workflow management.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-30"></div>
            </div>
        </div>
        <div className="space-60"></div>
    </>
  )
}

export default Features
