import React from 'react'
import axios from 'axios';

const Apis = () => {
    axios.defaults.baseURL = "https://admin.shopersbay.com/";

    axios.defaults.headers.common['authentication'] = "549681be7a669a80f686e15f8d8b2d79";

    const getMasterDataService = async () => {
        const response = await axios.get("asapi/getMasterData")
        return response;
    };

    const getNavigationlist = async () =>{
        const response =  await axios.get("asapi/getNavigationList");
        return response;
    }

    const getHomeDatalist = async () =>{
        const response =  await axios.get("asapi/getHomePageNewsData");
        return response;
    }

    const getDocumentationlist = async () =>{
        const response =  await axios.get("asapi/getDocumentData");
        return response;
    }

    const SearchDocument = async (input_data) => {
        const response = await axios.post("asapi/searchPost", input_data);
        return response;
    };

    return {
        getMasterDataService: getMasterDataService,
        getNavigationlist: getNavigationlist,
        getDocumentationlist: getDocumentationlist,
        SearchDocument: SearchDocument,
        getHomeDatalist: getHomeDatalist,
    }
}

export default Apis
