import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap'

const DocDetail = () => {
    const { slug } = useParams();
    const documentData = useSelector((state) => state.master.documentData);
    const [detail, setdetail] = useState([]);

    useEffect(()=>{
        if(documentData != null && documentData.data.length > 0){
            const result = documentData.data.map((category) => category.posts).flat().find((posts) => posts.slug === slug);
            if (result === undefined) {
                const result1 = documentData.data.filter((category) => category.child.length > 0);
                if (result1.length > 0) {
                    setdetail(result1.map(ele2 => {
                      return ele2.child.map(ele => {
                        var getdata = ele.posts.filter(ele9 => ele9.slug === slug);
                        if (getdata.length > 0) {
                          return ele.posts.filter(ele9 => ele9.slug === slug)[0]
                        }
                      }).filter(ekre => ekre !== undefined)[0]
                    }).filter(ekre => ekre !== undefined));
                  }
            }else {
                setdetail([result])
            }
        }
    },[documentData, slug])

  return (
    <>
    {
        detail.length > 0 &&
        <Container fluid>
        <Row>
          <Col lg={2} md={12} sm={12}></Col>
          <Col lg={10} md={12} sm={12} className='document-detail'>
            
              <div dangerouslySetInnerHTML={{ __html: detail[0].body }} />
              <div className='docs_review_border mt-5 mb-4'>
                <div className='docs_review'>
                  <p> Was this page helpful ? </p>
                  <div className='d-flex docs_review_btn_green'>
                    <div className='a'>Yes</div>
                    <div className='b'>No</div>
                  </div>
                </div>
              </div>
            
          </Col>
        </Row>
      </Container>
    }
    </>
  )
}

export default DocDetail
