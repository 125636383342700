import { configureStore } from '@reduxjs/toolkit'
import masterReducer from './slice/onload'
import toggleReducer from './slice/changeState'
import searchReducer from './slice/search'

const store = configureStore({
  reducer: {
    master : masterReducer,
    toggle: toggleReducer,
    search: searchReducer,
  },
})

export default store;