import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BsChevronLeft } from 'react-icons/bs';
import { fetchSearchDocument } from '../redux/slice/search';
import {setshowsearchdataFalse, setshowsearchdataTrue} from '../redux/slice/changeState'

const Search = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const seachshow = useSelector((state)=> state.toggle)
    const seachdata = useSelector((state)=> state.search)

    const handleChange = (e) => {
        e.preventDefault(); // prevent the default action
        dispatch(setshowsearchdataTrue())
        var input_data = {
            "search": e.target.value
        }

        dispatch(fetchSearchDocument(input_data))
    };

    return (
        <>
            <div className='search'>
                <BsChevronLeft onClick={goBack} size="24" className='search-left-icon' />
                <input type='text' className="custom-search-input" placeholder='Search for documentation' onChange={handleChange} />
            </div>
            
                <div className='searchDetail'>
                    {
                        seachshow.showsearchdata && seachdata.searchPost != null && seachdata.searchPost.data.length > 0 ? (
                            seachdata.searchPost.data.map((ele, index) => {
                                return (
                                    <NavLink key={index} onClick={() => dispatch(setshowsearchdataFalse())} to={`/document/${ele.slug}`} className="text-decoration-none">
                                        <div className='searchbar-product-list'>
                                            <h3>{ele.title}</h3>
                                            <div dangerouslySetInnerHTML={{ __html: ele.short_description }} />
                                        </div>
                                    </NavLink>
                                )
                            }))
                        :
                        <div>No record found</div>
                    }
                </div>
                
           
        </>
    )
}

export default Search
