import React from 'react'
import * as common from '../common'

const DesktopLayout = ({children}) => {
  
  return (
    <>
      <div className=" home-coporate">
        <div id="page" className="site">
            <common.Header/>
            {
                children
            }
            <common.Footer/>
        </div>
      </div>
    </>
  )
}

export default DesktopLayout
