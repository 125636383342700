import React from 'react'
import img2 from '../../images/img1.jpg'

const OneImage = () => {
  return (
    <>
        <div class="business-bg-light">
            <div class="container">
                <div class="space-60 d-none d-md-block"></div>
                <div class="space-60"></div>
                <div class="row align-items-center">
                    <div class="col-lg-6 mb-5 mb-lg-0">
                        <div class="business-about-desc">
                            <div class="ot-heading">
                                <span class="">MOBILE INSPECTION APP</span>
                                <h3 class="main-head">Optimize your operations with seamless, paperless efficiency</h3>
                            </div>
                            <div class="space-20"></div>
                            <div class="space-5"></div>
                            <p>Your operation is dynamic and evolving. Embrace a digital solution that simplifies change. Digitize processes, automate workflows, capture consistent data, and uncover opportunities for improvement from the ground up.</p>
                            <p>Empower your team with the knowledge of what’s working, what’s not, and the tools to take action.</p>
                            <div class="space-20"></div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <img src={img2} alt="" class="rounded-img" />
                    </div>
                </div>
                <div class="space-60 d-none d-md-block"></div>
                <div class="space-60"></div>
            </div>
        </div>
    </>
  )
}

export default OneImage
