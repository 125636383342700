import React from 'react'
import * as common from '../components/common'

const Home = () => {
  return (
    <>
      <common.Slider />
      <common.OneImage />
      <common.Features />
      <common.OneImage />
      <common.SuggestComponent />
      <div className='space-60'></div>
      <common.ContactComponent />
    </>
  )
}

export default Home
