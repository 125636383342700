import React from 'react'
import bg from '../../images/bg1-home4.jpg'
import ios from '../../images/app-store.svg'
import google from '../../images/google-play.svg'

const SuggestComponent = () => {
    return (
        <section className="coporate-mkt" style={{ backgroundImage: `url('${bg}')`}}>
            <div className="container">
                <div className="space-60 d-none d-md-block"></div>
                <div className="space-5"></div>
                <div className="row align-items-center ">
                    <h1 className='text-center'>Start your improvement momement</h1>
                </div>
                <div className="space-20"></div>
                <div className="row justify-content-center">
                    <div className='col-12'>
                        <div className='get-button'>
                            <button className='started-button octf-btn'>Get Started</button>
                            <button className='octf-btn'>Get Quote</button>
                        </div>
                    </div>
                </div>
                <div className="space-20"></div>
                <div className="row justify-content-center">
                    <div className='app-section'>
                        <img src={ios} alt="" className='ios-button'></img>
                        <img src={google} alt=""></img>
                    </div>
                </div>
                <div className="space-60 d-none d-md-block"></div>
                <div className="space-60"></div>
            </div>
        </section>
    )
}

export default SuggestComponent
