import React, {useState} from 'react'
import InputBox from './InputBox'
import { ContactFormRules } from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import { useMediaQuery } from 'react-responsive';

const ContactForm = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

    const [formValues, setFormValues] = useState(
        {
          "name": "",
          "phone": "",
          "email": "",
          "subject": "",
          "message":"",
        }
    );

    const { values, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        ContactFormRules,
        formValues
    );

    function handleNext() {
        // dispatch(saveContactFormdatalist(values,setFormValues))
    }

    return (
        <>
        <div className="main-form">
            <div className="row">
                <p className="col-md-6">
                    <InputBox label={"Your Name *"} id={"name"} handleChange={handleChange} errors={errors} value={values.name} />
                </p>
                <p className="col-md-6">
                    <InputBox label={"Your Email *"} id={"email"} handleChange={handleChange} errors={errors} value={values.email}/>
                </p>
            </div>
            <div className="row">
                <p className="col-md-6">
                    <InputBox label={"Your Phone *"} id={"phone"} handleChange={handleChange} errors={errors} value={values.phone}/>
                </p>
                <p className="col-md-6">
                    <InputBox label={"Your Subject *"} id={"subject"} handleChange={handleChange} errors={errors} value={values.subject} />
                </p>
            </div>
            <p className="mb-0">
                <textarea name="message" cols="40" rows="10" placeholder="Message..." onChange={handleChange} value={values.message}></textarea>
            </p>
            <div className="mb-0">
                <button type="submit" className="octf-btn" onClick={handleSubmit}>submit now</button>
                {/* {
                loader &&
                <div style={{ position: 'absolute', top: '36%', left: isMobile ? '82%' :'54%', transform: 'translate(-50%, -50%)' }}>
                    <Spinner animation="border" role="status" className='custom-spinners' />
                </div>
                } */}
            </div>
        </div>
        </>
    )
}

export default ContactForm
