import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import {FaBars} from 'react-icons/fa';
import {IoMdClose} from 'react-icons/io';
import { setshowSidebarFalse, setshowSidebarTrue} from '../../redux/slice/changeState';
import AllCategories from './AllCategories';

const DocMobileNavigation = () => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const sidebar = useSelector(state => state.toggle.showSidebar);
 
    return (
        <>
            <div style={{ display: "flex" }} >
                <FaBars style={{ fontSize: isMobile ? "24px" : "" }} onClick={()=> dispatch(setshowSidebarTrue())}  />
            </div>
            {sidebar &&
            <div className='sidebar-section' style={{left: sidebar ? '0' : '-100%'}}>
                <div className='sidebar-top'>
                    <div className='sidebar-topleft'>
                        <h4 className='title'>
                            Documentation
                        </h4>
                    </div>
                    <div className='topleft-icon'>
                        <IoMdClose size="23" className='close-icon-sidebar' onClick={() => {dispatch(setshowSidebarFalse())}} />
                    </div>
                    
                </div>
                <div className='menu-list'>
                        <AllCategories />
                    </div>
            </div>
            }
        </>
    )
}

export default DocMobileNavigation
