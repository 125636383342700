import React from 'react'
import * as common from '../components/common'

const ContactUs = () => {
  return (
    <>
        <common.ContectContent />
        <common.ContactComponent />
    </>
  )
}

export default ContactUs
